import pdfMake from 'pdfmake';
import handlePurchase from "@/services/modules/purchase";
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const POChallanPdfPrinter = () => { 
    const {currencyToWord} = handlePurchase();
    const exportToPDF = (company, product, barcode, qrcode, userName, btnFunc) => {
        const tableItems = product.general
        var doc = {
            pageSize: 'A4',
            pageMargins: [ 60, 100, 60, 80 ],
            header: getHeader(company),
            footer: getFooter(userName, qrcode),
            content: getContent(product, tableItems, barcode, btnFunc),

            styles : {
                header: {
                    fontSize: 24,
                    bold: true,
                    font: 'Roboto'
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'Roboto'
            },
            info: {
                title: product.challan_number
            },
            // Define fonts
            fonts: {
                Roboto: {
                    normal: 'Roboto-Regular.ttf',
                    bold: 'Roboto-Bold.ttf',
                    italics: 'Roboto-Italic.ttf',
                    bolditalics: 'Roboto-BoldItalic.ttf'
                }
            }
        }

        pushIntoTable(doc, product, tableItems, btnFunc)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }
    const formatDate = (date) => {
        var formattedDate = date.toISOString().split('T')[0];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
        return formatToDdMmYy(formattedDate) + ' ' + formattedTime
    }
    const formatToDdMmYy = (date) => {
        var dat = date.split('-');
        return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }
    const getHeader = (company) => {
        return {
            margin: [ 60, 25, 60, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60
                },

                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email
                    ]
                }
            ]
        }
    }
    const getContent = (product, tableItems, barcode, btnFunc) => {
        const profileItems = product.contact ? product.contact.profile_items : [];
        const buyerName = product.buyer ? product.buyer.name : null;
        return  [
            {
                text: 'DELIVERY CHALLAN',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                text: 'Customer Details',
                                color: 'black',
                                bold: true,
                            },
                            {
                                text: product.contact ? product.contact.name : null,
                                alignment: 'left',
                                width: '50%'
                            },
                            {
                                text: getProfileItemByField(profileItems, 'address'),
                            },
                            {
                                text: getProfileItemByField(profileItems, 'billing_address'),
                            },
                            {
                                text: product.contact.address ?? ''
                            },
                            {
                                text: buyerName != null ? 'Buyer Name: ' + buyerName : '',
                                color: 'black',
                            },
                            {
                                text: product.sales_order && product.sales_order.country != null ? 'Country Of Origin: ' + product.sales_order.country  : '',
                                color: 'black',
                            },
                            {
                                text: product.sales_order && product.sales_order.loading_port != null ? 'Loading Port: ' + product.sales_order.loading_port : '',
                                color: 'black',
                            },
                            {
                                text: product.sales_order && product.sales_order.delivery_port != null ? 'Delivery Port: ' + product.sales_order.delivery_port : '',
                                color: 'black',
                            },
                        ]
                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                svg: barcode
                            },

                            {
                                text: [
                                    {
                                        text: 'CHALLAN NO: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: product.challan_number
                                    }
                                 ]
                            },
                            {
                                text: [
                                    {
                                        text: 'CHALLAN DATE: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: formatToDdMmYy(product.challan_date),
                                    }
                                 ]
                            },

                            {
                                text: [
                                    {
                                        text: 'PI NO: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: product.sales_order ? product.sales_order.bill_number : '-'
                                    }
                                 ]
                            },

                            {
                                text: [
                                    {
                                        text: 'PI DATE: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: product.sales_order ? product.sales_order.date : '-'
                                    }
                                 ]
                            },

                            {
                                stack: [
                                    {
                                        text: product.location_id ? 'SHIP FROM:' : '',
                                        bold: true
                                    },

                                    {
                                        text: product.business_location ? product.business_location.name : ''
                                    }
                                ]
                            }
                        ]
                    },
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: []
                },
                layout: {
			        fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
		        }
		    },

            {
                columns: [
                    {
                        width: '45%',
                        text: [
                            {
                                text: '',
                                bold: true
                            }
                        ]
                    },
                     {
                        table: {
                            widths: '15%',
                            alignment: 'right',
                            body: formatTotals(product)
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        }
                    }
                ],
                margin: [0, 10]
            },
            {
                alignment: 'justify',
                text: [{ text: product.description ? 'NOTE: \n' : '', bold: true}, {text: product.description} ]
            }

        ]
    }
    const getFooter = (userName, qrcode) =>{
        return {
            margin: [ 60, 0, 60, 0 ],
            columns: [
                {
                    columns: [
                        {
                            svg: qrcode
                        },

                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]
                },

                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    const formatTotals = (product) => {
        const totals = [
            [{ text: 'Total' }, { text: product.total_quantity, alignment: 'right'}],
        ]

        return totals;
    }

    const getProfileItemByField = (profile_items, keyValue) => {
        const item = profile_items.find(item => item.field === keyValue);
        return (item) ? item.value : '';
    }

    let srNumber = 0;
    const pushIntoTable = (doc, product, tableItems, btnFunc) => {
        srNumber = 0;
        var flag = false;
        tableItems.forEach( (item, index) => {
            if( !flag ){
                const tableHeader = getTableHeader(btnFunc);
                doc.content[2].table.body.push(tableHeader);
                flag = true;
            }
            const rowData = getRowData(item);
            return doc.content[2].table.body.push(rowData);
        });
    }

    const getTableHeader = () => {
        let headerInvoice = [
            { text: 'SL #' },
            { text: 'Style' },
            { text: 'PO Number' },
            { text: 'Item' },
            { text: 'Color' },
            { text: 'Size' },
            { text: 'UoM' },
            { text: 'Qty' },
            { text: 'G.W (KG)' },
            { text: 'N.W (KG)' },
            { text: 'Remarks' }
        ]
        
        return headerInvoice;
    }

    const getRowData = (item) => {
        srNumber += 1;
        const brandName = getSpecificationName(item, 0);
        const colorName = getSpecificationName(item, 1);
        const sizeName = getSpecificationName(item, 2);
        const unitNameValue = unitName(item);

        let rowInvoice = [ 
            { text:  srNumber },
            { text: (item.sales_order_general ? item.sales_order_general.style : '-') },
            { text: (item.sales_order_general ? item.sales_order_general.po_number : '-') },
            { text: brandName },
            { text: colorName },
            { text: sizeName },
            { text: unitNameValue },
            { text:  item ? item.delivered_qty : '', alignment: 'right' },
            { text: item ? item.gross_weight : '', alignment: 'right' },
            { text: item ? item.net_weight : '', alignment: 'right' },
            { text: item ? item.description : '', alignment: 'right' }
        ] 
        return rowInvoice;
    }

    const getSpecificationName = (item, index) => {
        if (item && item.product && item.product.specifications && item.product.specifications[index] && item.product.specifications[index].specifiable && item.product.specifications[index].specifiable.name) {
            return item.product.specifications[index].specifiable.name;
        }
        return '-';
    }

    const unitName = (item) => {
        if (item && item.product && item.product.description && item.product.description.unit && item.product.description.unit.name) {
            return item.product.description.unit.name;
        }
        return item.head.name;
    }

    return {
        exportToPDF
    }
}

export default POChallanPdfPrinter;
