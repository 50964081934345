<template>
  <div class="card" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Customer Name</th>
          <th>Order Date</th>
          <th>Order Number</th>
          <th>Challan Date</th>
          <th>Challan Number</th>
          <th class="text-right">Challan Qty</th>
          <th>Action</th>
          <th v-if="route_name === 'delivery-challan-list'">Print Challan</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in purchase" :key="i">
          <td> {{ item.party_name }}</td>
          <td> {{ item.sales_order_date }}</td>
          <td> {{ item.sales_order_no }}</td>
          <td class="text-right"> {{ item.date }}</td>
          <td class="text-right"> {{ item.bill_number }}</td>
          <td class="text-right"> {{ formatNumber(item.total_qty)}}</td>
          <td>
            <button class="btn btn-primary btn-sm"
              @click="createInvoice(item.id)"
              style="cursor: pointer"
              v-if="!item.sale_master_id && route_name === 'pending-delivery-challan-list'"
            >
              Create Invoice
            </button>
            <span v-if="route_name === 'delivery-challan-list'" class="d-flex justify-content-center gap-1">
              <button class="btn btn-primary btn-sm"
                      style="cursor: pointer"
                      @click="goToView(item.id)"
              >
                <i class="fas fa-eye"></i>
              </button>
              <button class="btn btn-primary btn-sm"
                @click="goToEdit(item.sales_order_master_id, item.id)"
                style="cursor: pointer"
                v-if="!item.sale_master_id"
              >
                <i class="fas fa-edit"></i>
              </button>
            </span>
          </td>
          <td v-if="route_name === 'delivery-challan-list'">
            <button class="btn btn-secondary btn-sm"
              @click="goToPrint(item.id)"
              style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-print"></i>
            </button>
          </td>
        </tr>

        </tbody>
      </table>
      <p v-if="!purchase.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter';
import handlePurchase from "@/services/modules/purchase";
import POChallanPdfPrinter from '@/services/utils/POChallanPdfPrinter';
import handleCompany from "@/services/modules/company";
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";
import {formatNumber} from "chart.js/helpers";

export default {
  name: 'PurchaseListTable',
  data() {
    return{
      companyInfo: {},
      invoice: {},
      product: {},
      barcodeQuery: '',
      barcode: '',
      user: {},
      qrcode: ''
    }
  },
  props: {
    purchase: Array,
    route_name: {
      type: String,
      default: 'pending-delivery-challan-list'
    },
  },
  setup() {
    const {commaFormat} = figureFormatter()
    const { fetchDeliveryChallan } = handlePurchase();
    const {fetchCompanyInfo} = handleCompany();
    const { exportToPDF } = POChallanPdfPrinter();
    const { fetchBarcode } = handleBarcode();
    const store = useStore();

    return {
      commaFormat,
      fetchDeliveryChallan,
      exportToPDF,
      fetchCompanyInfo,
      fetchBarcode,
      store
    }
  },
  mounted() {
    this.fetchCompanyInfo(this.$route.params.companyId).then( (res) => {
      this.companyInfo = res.data;
    }).catch((error) => {
      console.log(error);
    });

    this.user = this.store.state.user.user
  },
  methods: {
    formatNumber,
    createInvoice(id) {
      let param = {
        companyId: this.$route.params.companyId,
        moduleId: this.$route.params.moduleId,
        menuId: this.$route.params.menuId,
        pageId: this.$route.params.pageId,
      }
      param['challanId'] = id;
      this.$router.push({
        name: 'create-invoice-from-challan',
        params: param,
        query: this.$route.query
      })
    },
    goToPrint(id) {
      const companyQuery = `?company_id=${this.$route.params.companyId}`;
      this.fetchDeliveryChallan(id, companyQuery).then( (res) => {
        if( res ){
          this.product = res.data;
          let qrcodeData = JSON.stringify({
            ref_no: this.product.challan_number,
            ref_date: this.product.challan_date,
            created_by: this.user.name,
            created_date_time: this.formatDate(new Date())
          })
          this.barcodeQuery = `?code=${this.product.challan_number}&qrcode=${qrcodeData}`;     
        }}).then (() => {
          this.fetchBarcode(this.barcodeQuery).then( (res) => {
          this.barcode = res.barcode;
          this.qrcode = res.qrcode
        }).then( () => {
          this.exportToPDF(this.companyInfo, this.product, this.barcode, this.qrcode, this.user.name, 1)
        })  
      }).catch( (err) => {
          console.log("Something went wrong");
      })
    },
    formatDate(date) {
      const formattedDate = date.toISOString().split('T')[0];
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12;
      const formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;

      return this.formatToDdMmYy(formattedDate) + ' ' + formattedTime
    },
    formatToDdMmYy(date) {
      const dat = date.split('-');
      return `${dat[2]}-${dat[1]}-${dat[0]}`;
    },
    goToEdit(salesOrderId, challanId) {
      let param = {
        companyId: this.$route.params.companyId,
        moduleId: this.$route.params.moduleId,
        menuId: this.$route.params.menuId,
        pageId: this.$route.params.pageId,
        salesOrderId: salesOrderId
      }
      this.$router.push({
        name: "delivery-challan-edit",
        params: param,
        query: {...this.$route.query, challanId: challanId},
      })
    },
    goToView(challanId) {
      let param = {
        companyId: this.$route.params.companyId,
        moduleId: this.$route.params.moduleId,
        menuId: this.$route.params.menuId,
        pageId: this.$route.params.pageId,
        challanId: challanId
      }
      this.$router.push({
        name: "delivery-challan-details",
        params: param,
        query: this.$route.query,
      })
    }
  }
}
</script>
